<template>
  <v-row>
    <v-col cols="12">
      <v-alert
        border="left"
        type="info"
      >
        <p>
          {{ user.name }} is exempt from internet disconnection until
          <b>{{ formattedDisconnectExemptionTillDate }}</b>
        </p>
        <v-btn
          v-if="can('customer-edit')"
          color="error"
          class="ml-2"
          :loading="isCancelingDisconnectionExemption"
          @click="cancelDisconnectionExemption"
        >
          <v-icon left>
            {{ icons.cancel }}
          </v-icon>
          Remove Exemption
        </v-btn>
        <v-btn
          v-if="can('customer-edit')"
          color="success"
          class="ml-2"
          @click="updateDisconnectionExemptionDate"
        >
          <v-icon left>
            {{ icons.cash }}
          </v-icon>
          Update Exemption Date
        </v-btn>
      </v-alert>
    </v-col>
    <exempt-from-disconnection-dialog
      :show-dialog="showExemptFromDisconnectionDialog"
      :customer-name="user.name"
      :customer-id="user.customer.id"
      :disconnection-exemption="user.customer.disconnection_exemption"
      @exempted-from-disconnection="onExemptedFromDisconnection"
      @close="showExemptFromDisconnectionDialog = false"
    />
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mdiInformationOutline, mdiCashMultiple, mdiCancel } from '@mdi/js'
import ExemptFromDisconnectionDialog from '@/components/dialogs/ExemptFromDisconnectionDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    ExemptFromDisconnectionDialog,
  },
  mixins: [hasPermission],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showExemptFromDisconnectionDialog: false,
      isCancelingDisconnectionExemption: false,
      icons: {
        info: mdiInformationOutline,
        cash: mdiCashMultiple,
        cancel: mdiCancel,
      },
    }
  },
  computed: {
    formattedDisconnectExemptionTillDate() {
      return this.user.customer.disconnection_exemption?.end_time
        ? moment(this.user.customer.disconnection_exemption.end_time).format('DD MMM YYYY, hh:mm A')
        : ''
    },
  },
  methods: {
    updateDisconnectionExemptionDate() {
      this.showExemptFromDisconnectionDialog = true
    },
    cancelDisconnectionExemption() {
      this.isCancelingDisconnectionExemption = true
      axios.delete(`disconnection-exemption/${this.user.customer.disconnection_exemption.id}`)
        .then(() => {
          this.$toast.success('Disconnection exemption removed successfully')
          this.$emit('cancelled-disconnection-exemption')
        })
        .catch(error => {
          console.error('Error cancelling disconnection exemption:', error)
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isCancelingDisconnectionExemption = false
        })
    },
    onExemptedFromDisconnection({ data }) {
      this.$emit('exempted-from-disconnection', { data })
    },
  },
}
</script>
