<template>
  <v-card :min-height="210">
    <v-card-title class="mb-0 pb-1">
      Internet Plan Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!customerPlan && !isLoading"
            cols="12"
          >
            <h3 class="font-weight-light">
              No customer plan found
            </h3>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan && customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Plan Name
              </h4>
              <h4 class="font-weight-medium">
                {{ customerPlan.internet_plan.name }}
              </h4>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan&& customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Upload Speed
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ customerPlan.internet_plan.upload_speed }} {{ getSpeedUnit(customerPlan.internet_plan.upload_speed_unit) }}
              </h4>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan && customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Download Speed
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ customerPlan.internet_plan.download_speed }} {{ getSpeedUnit(customerPlan.internet_plan.download_speed_unit) }}
              </h4>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan && customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Price
              </h4>
              <h4 class="font-weight-medium">
                <!-- Check if there is a discount or custom price -->
                <template v-if="customerPlan.has_discount || customerPlan.has_custom_price">
                  <!-- Strikethrough on original price -->
                  <span class="strikethrough">Ksh {{ customerPlan.internet_plan.price | formatCurrency }}</span>
                  <br>
                  <!-- Effective price after discount or custom price -->
                  <span class="custom-price">Ksh {{ customerPlan.effective_price | formatCurrency }}</span>
                </template>
                <!-- Otherwise, show the standard price -->
                <template v-else>
                  Ksh {{ customerPlan.internet_plan.price | formatCurrency }}
                </template>
                <v-tooltip
                  v-if="shouldShowTooltip"
                  max-width="300"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      style="cursor: pointer;"
                      v-on="on"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span v-html="priceTooltipMessage"></span>
                </v-tooltip>
              </h4>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan && customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Validity
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ formatValidity(customerPlan.internet_plan.validity_unit, customerPlan.internet_plan.validity) }}
              </h4>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customerPlan && customerPlan.internet_plan"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                Expiry Date
              </h4>
              <h4 class="font-weight-medium">
                <template v-if="customerPlan.end_date !== finalExpiryDate">
                  <!-- Strikethrough on original end_date -->
                  <span class="strikethrough">{{ customerPlan.end_date | formatDate }}</span>
                  <br>
                  <!-- Show effective end date -->
                  <span>{{ finalExpiryDate | formatDate }}</span>
                  <!-- Tooltip for explanation -->
                  <v-tooltip
                    max-width="300"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="primary"
                        class="ml-2"
                        v-bind="attrs"
                        style="cursor: pointer;"
                        v-on="on"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span v-html="expiryDateTooltipMessage"></span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ finalExpiryDate | formatDate }}
                </template>
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import internetSpeedEnum from '@/enums/internetSpeedEnum'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  mixins: [billingIntervalName],
  props: {
    customerPlan: {
      type: Object,
      default: null,
    },
    discount: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    icons: {
      mdiInformationOutline,
    },
  }),
  computed: {
    shouldShowTooltip() {
      return this.customerPlan.has_discount || this.customerPlan.has_custom_price
    },
    priceTooltipMessage() {
      if (!this.customerPlan.internet_plan) {
        // Return a fallback message or handle the null case appropriately
        return 'No internet plan is selected.'
      }
      const originalPriceFormatted = this.customerPlan.internet_plan.price
      const customPriceFormatted = this.customerPlan.custom_price
      const effectivePriceFormatted = this.customerPlan.effective_price
      const discountMessage = this.formatDiscount(this.discount)

      if (this.customerPlan.has_custom_price && this.customerPlan.has_discount) {
        return `Original price for ${this.customerPlan.internet_plan.name} plan is Ksh ${originalPriceFormatted}. Both a custom price of Ksh ${customPriceFormatted} and ${discountMessage} are applied, resulting in the effective price of Ksh ${effectivePriceFormatted}.`
      } if (this.customerPlan.has_custom_price) {
        return `Original price for ${this.customerPlan.internet_plan.name} plan is Ksh ${originalPriceFormatted}, but a custom price of Ksh ${customPriceFormatted} has been set for this customer, resulting in the effective price of Ksh ${effectivePriceFormatted}.`
      } if (this.customerPlan.has_discount) {
        return `The original price of Ksh ${originalPriceFormatted} for the ${this.customerPlan.internet_plan.name} plan is reduced by ${discountMessage}, resulting in the effective price of Ksh ${effectivePriceFormatted}.`
      }

      return `The price for the ${this.customerPlan.internet_plan.name} plan is Ksh ${originalPriceFormatted}.`
    },
    expiryDateTooltipMessage() {
      const { formatDate } = this.$options.filters

      if (this.discount?.type === 1 && parseFloat(this.discount.value) === 100) {
        // If there's a 100% discount, use the discount's end_date as the effective expiry date
        return `The original expiry date was set to ${formatDate(this.customerPlan.end_date)}, but the 100% discount is applied, resulting in a new effective expiry date of ${formatDate(this.discount.end_date)}.`
      }

      if (this.customerPlan.end_date !== this.customerPlan.effective_end_date) {
        return `The original expiry date was set to ${formatDate(this.customerPlan.end_date)}, but a disconnection exemption has been applied, resulting in a new effective expiry date of ${formatDate(this.customerPlan.effective_end_date)}.`
      }

      return ''
    },
    finalExpiryDate() {
    // Parse the dates to ensure proper comparison
      const discountEndDate = new Date(this.discount?.end_date)
      const effectiveEndDate = new Date(this.customerPlan.effective_end_date || this.customerPlan.end_date)

      // Check if there's a 100% discount and if discount end date is valid and greater than the effective end date
      if (this.discount?.type === 1 && parseFloat(this.discount.value) === 100 && discountEndDate > effectiveEndDate) {
        return this.discount.end_date
      }

      // Otherwise, return the effective end date or fallback to customerPlan.end_date
      return this.customerPlan.effective_end_date || this.customerPlan.end_date
    },
  },
  methods: {
    getSpeedUnit(unit) {
      switch (unit) {
        case internetSpeedEnum.KBPS:
          return 'Kbps'
        case internetSpeedEnum.MBPS:
          return 'Mbps'
        case internetSpeedEnum.GBPS:
          return 'Gbps'
        default:
          return 'Mbps' // Default unit, you can change this as required.
      }
    },
    formatDiscount(discount) {
      const { formatDate } = this.$options.filters
      if (!discount) return ''
      const valueFormatted = discount.type === 1 ? `${parseInt(discount.value, 10)}%` : `Ksh ${discount.value}`

      return `a discount of ${valueFormatted} valid until ${formatDate(discount.end_date)}`
    },
  },
}
</script>

<style scoped>
.strikethrough {
  text-decoration: line-through;
  color: #9E9E9E; /* Gray color for the original price */
  margin-right: 10px;
}

.custom-price {
  color: #4CAF50; /* Green color for the custom price */
}
</style>
