<template>
  <div class="customers">
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="hidden-sm-and-down"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon class="mr-1">
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs v-model="tab">
      <v-tab key="overview">
        Overview
      </v-tab>
      <v-tab key="connection">
        Connection
      </v-tab>
      <v-tab key="billing">
        Billing
      </v-tab>
      <v-tab key="communications">
        Communications
      </v-tab>
      <v-tab key="settings">
        Settings
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="overview">
        <v-row
          align="center"
          class="mb-1"
        >
          <v-col
            cols="12"
            md="5"
          >
            <h3
              v-show="!isLoading"
              class="font-weight-light pt-2"
            >
              Details about <span class="font-weight-medium">{{ details.first_name }}</span>
            </h3>
            <vue-skeleton-loader
              v-show="isLoading"
              :width="350"
              :height="21"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="details.customer.has_internet_access"
              inset
              :label="details.customer.has_internet_access ? 'Internet On' : 'Internet Off'"
              :loading="isTogglingInternetStatus"
              :disabled="isLoading || isTogglingInternetStatus || !can('customer-edit')"
              color="success"
              @change="toggleInternetStatus(details.customer.has_internet_access)"
            >
            </v-switch>
          </v-col>
          <v-spacer />
          <v-col
            class="text-md-right"
            cols="12"
            md="3"
          >
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  Actions
                  <v-icon right>
                    {{ icons.mdiMenuDown }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="can('customer-edit') && details.customer.can_update_installation_fee"
                  @click="showAddInstallationFeeDialog = true"
                >
                  <v-list-item-title>Add Installation Fee</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('payment-create')"
                  @click="showAddPaymentDialog = true"
                >
                  <v-list-item-title>Add Payment</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-edit')"
                  @click="showAddDebtDialog = true"
                >
                  <v-list-item-title>Add Debt</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('sms-create')"
                  @click="showSendSmsDialog = true"
                >
                  <v-list-item-title>Send Sms</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-edit')"
                  :disabled="isSendingPortalCredentialsMessage"
                  @click="showPortalCredentialsMessageDialog = true"
                >
                  <v-list-item-title>Send Portal Credentials Message</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-edit') && !details.customer.has_disconnection_exemption"
                  @click="showExemptFromDisconnectionDialog = true"
                >
                  <v-list-item-title>Exempt From Disconnection</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-edit')"
                  @click="showUpdateCustomerInternetPlanDialog = true"
                >
                  <v-list-item-title>Update Internet Plan</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-edit')"
                  :disabled="isSendingPortalCredentialsMessage"
                  @click="showEditCustomerDialog = true"
                >
                  <v-list-item-title>Edit Customer</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="can('customer-delete')"
                  :disabled="isConfirmDeleteDialogButtonLoading"
                  @click="showConfirmDeleteDialog = true"
                >
                  <v-list-item-title>Delete Customer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-expand-transition>
          <disconnection-exemption-notice
            v-show="details.customer.has_disconnection_exemption"
            :user="details"
            @exempted-from-disconnection="onExemptedFromDisconnection"
            @cancelled-disconnection-exemption="details.customer.has_disconnection_exemption = false"
          />
        </v-expand-transition>
        <v-expand-transition>
          <pending-plan-change-alert
            v-show="details.customer.has_pending_plan_change"
            :customer="details.customer"
            :customer-name="details.name"
            @planChangeCanceled="onPlanChangeCanceled"
          />
        </v-expand-transition>
        <v-expand-transition>
          <scheduled-plan-change-alert
            v-show="details.customer.has_scheduled_plan_change"
            :customer="details.customer"
            :customer-name="details.name"
            @planScheduledCanceled="details.customer.has_scheduled_plan_change = false"
          />
        </v-expand-transition>
        <customer-details-card
          :is-loading="isLoading"
          :user="details"
        />
        <v-expand-transition>
          <installation-fee-details-card
            v-show="details.customer.should_pay_installation_fee"
            :is-loading="isLoading"
            :customer="details.customer"
          />
        </v-expand-transition>
        <div class="spacer-v"></div>
        <customer-plan-details-card
          :customer-plan="details.customer.customer_plan"
          :discount="details.customer.discount"
          :is-loading="isLoading"
        />
        <div class="spacer-v"></div>
        <activity-logs-table
          :url="'activity-logs'"
          :user-id="userId"
        />
        <div class="spacer-v"></div>
      </v-tab-item>
      <v-tab-item key="connection">
        <div class="spacer-v"></div>
        <customer-plan-details-card
          :customer-plan="details.customer.customer_plan"
          :discount="details.customer.discount"
          :is-loading="isLoading"
        />
        <div class="spacer-v"></div>
        <internet-connection-details
          :internet-connection-details="details.internet_connection_details"
          :is-loading="isLoading"
        />
        <div class="spacer-v"></div>
        <internet-traffic-details
          :customer-id="details.customer.id"
          :is-loading="isLoading"
        />
        <div class="spacer-v"></div>
      </v-tab-item>
      <v-tab-item key="billing">
        <div class="spacer-v"></div>
        <invoices-table
          :invoices-url="'invoices'"
          :customer-id="details.customer.id"
        />
        <div class="spacer-v"></div>
        <div class="spacer-v"></div>
        <payments-table
          :payments-url="'payments'"
          :user-id="userId"
        />
        <div class="spacer-v"></div>
      </v-tab-item>
      <v-tab-item key="communications">
        <div class="spacer-v"></div>
        <sms-table
          :sms-url="smsUrl"
          :for-specific-customer="true"
        />
        <div class="spacer-v"></div>
      </v-tab-item>
      <v-tab-item key="settings">
        <div class="spacer-v"></div>
        <v-card>
          <v-col>
            <v-row
              no-gutters
              align="center"
            >
              <v-col
                cols="auto"
                class="mr-6"
              >
                <v-label>
                  Use custom settings:
                </v-label>
              </v-col>
              <v-col cols="auto">
                <v-switch
                  v-model="details.customer.uses_custom_settings"
                  inset
                  :loading="isTogglingUsesCustomSettingsStatus"
                  :disabled="isLoading || isTogglingUsesCustomSettingsStatus"
                  color="success"
                  @change="toggleCustomSettingsStatus(details.customer.uses_custom_settings)"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-row
            v-if="!isSettingsLoaded"
            justify="center"
            align="center"
            class="full-height"
          >
            <v-progress-linear
              v-if="!isSettingsLoaded"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-row>
          <div v-else>
            <div v-if="Object.keys(settings).length === 0">
              <v-card-text>
                <p class="font-weight-medium">
                  This customer is currently using global settings, which apply to all customers.
                </p>
                <p>
                  If you want to use custom settings for this customer, toggle the switch above.
                </p>
                <p>
                  To update global settings,
                  <router-link :to="{ path: '/settings', query: { tab: 'system-settings' } }">
                    click here.
                  </router-link>
                </p>
              </v-card-text>
            </div>
            <settings-tabs
              v-else
              :settings="settings"
              :errors="errors"
            />
            <div class="spacer-v"></div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <add-customer-dialog
      :show-dialog="showEditCustomerDialog"
      :user="details"
      @close="showEditCustomerDialog = false"
      @user-updated="getDetails"
    />
    <add-installation-fee-dialog
      :show-dialog="showAddInstallationFeeDialog"
      :user="details"
      @added="getDetails"
      @close="showAddInstallationFeeDialog = false"
    />
    <add-payment-dialog
      :show-dialog="showAddPaymentDialog"
      :user-id="userId"
      @close="showAddPaymentDialog = false"
      @paymentAdded="onPaymentAdded"
    />
    <add-debt-dialog
      :show-dialog="showAddDebtDialog"
      :user="details"
      @close="showAddDebtDialog = false"
      @debtAdded="getDetails"
    />
    <send-sms-dialog
      :show-dialog="showSendSmsDialog"
      :user-id="userId"
      @close="showSendSmsDialog = false"
      @sent="showSendSmsDialog = false;"
    />
    <exempt-from-disconnection-dialog
      :show-dialog="showExemptFromDisconnectionDialog"
      :customer-name="details.name"
      :customer-id="details.customer.id"
      @exempted-from-disconnection="onExemptedFromDisconnection"
      @cancelled-disconnection-exemption="details.customer.has_disconnection_exemption = false"
      @close="showExemptFromDisconnectionDialog = false"
    />
    <update-customer-internet-plan-dialog
      v-model="showUpdateCustomerInternetPlanDialog"
      :user="details"
      @close="showUpdateCustomerInternetPlanDialog = false"
      @internet-plan-updated="getDetails"
    />
    <confirm-dialog
      :show-dialog="showInternetDisconnectDialog"
      :title="internetDisconnectDialogTitle"
      :message="internetDisconnectDialogMessage"
      :agree-text="internetDisconnectDialogAgreeText"
      :is-agree-button-loading="isTogglingInternetStatus"
      @agree="internetDisconnectDialogOnAgree"
      @cancel="showInternetDisconnectDialog = false; details.customer.has_internet_access = !details.customer.has_internet_access"
    />
    <confirm-dialog
      :show-dialog="showPortalCredentialsMessageDialog"
      :title="portalCredentialsMessageDialogTitle"
      :message="portalCredentialsMessageDialogMessage"
      :agree-text="portalCredentialsMessageDialogAgreeText"
      :is-agree-button-loading="isSendingPortalCredentialsMessage"
      @agree="confirmAndSendPortalCredentialsMessage(details.customer.id)"
      @cancel="showPortalCredentialsMessageDialog = false"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Delete customer?'"
      :agree-text="'Delete'"
      :message="`Are you sure you want to delete ${details.name} from customers?`"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteUser()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiPlus, mdiArrowLeft, mdiWifi, mdiWifiOff, mdiMenuDown, mdiInformationOutline,
} from '@mdi/js'
import VueSkeletonLoader from 'skeleton-loader-vue'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import AddCustomerDialog from '@/components/dialogs/AddCustomerDialog.vue'
import AddInstallationFeeDialog from '@/components/dialogs/AddCustomerInstallationFeeDialog.vue'
import AddPaymentDialog from '@/components/dialogs/AddPaymentDialog.vue'
import AddDebtDialog from '@/components/dialogs/AddCustomerDebtDialog.vue'
import SendSmsDialog from '@/components/dialogs/SendSmsDialog.vue'
import CustomerDetailsCard from '@/components/details-card/CustomerDetailsCard.vue'
import InstallationFeeDetailsCard from '@/components/details-card/InstallationFeeDetailsCard.vue'
import CustomerPlanDetailsCard from '@/components/details-card/CustomerPlanDetailsCard.vue'
import InternetConnectionDetails from '@/components/details-card/InternetConnectionDetails.vue'
import InternetTrafficDetails from '@/components/details-card/InternetTrafficDetails.vue'
import ExemptFromDisconnectionDialog from '@/components/dialogs/ExemptFromDisconnectionDialog.vue'
import UpdateCustomerInternetPlanDialog from '@/components/dialogs/UpdateCustomerInternetPlanDialog.vue'
import DisconnectionExemptionNotice from '@/components/partials/DisconnectionExemptionNotice.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'
import ScheduledPlanChangeAlert from '@/components/partials/ScheduledPlanChangeAlert.vue'
import InvoicesTable from '@/components/tables/InvoicesTable.vue'
import PaymentsTable from '@/components/tables/PaymentsTable.vue'
import SmsTable from '@/components/tables/SmsTable.vue'
import ActivityLogsTable from '@/components/tables/ActivityLogsTable.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import PortalCredentialsMessageMixin from '@/mixins/portalCredentialsMessageMixin'
import SettingsTabs from '@/components/partials/SettingsTabs.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    Breadcrumb,
    VueSkeletonLoader,
    CustomerDetailsCard,
    InstallationFeeDetailsCard,
    CustomerPlanDetailsCard,
    InvoicesTable,
    PaymentsTable,
    SmsTable,
    ActivityLogsTable,
    ConfirmDialog,
    InternetConnectionDetails,
    InternetTrafficDetails,
    ExemptFromDisconnectionDialog,
    UpdateCustomerInternetPlanDialog,
    DisconnectionExemptionNotice,
    PendingPlanChangeAlert,
    ScheduledPlanChangeAlert,
    AddCustomerDialog,
    AddInstallationFeeDialog,
    AddPaymentDialog,
    AddDebtDialog,
    SendSmsDialog,
    SettingsTabs,
  },
  mixins: [PortalCredentialsMessageMixin, hasPermission],
  data() {
    return {
      isLoading: true,
      isTogglingInternetStatus: false,
      isTogglingUsesCustomSettingsStatus: false,
      isSendingWelcomeMessage: false,
      isConfirmDeleteDialogButtonLoading: false,
      showInternetDisconnectDialog: false,
      showExemptFromDisconnectionDialog: false,
      showWelcomeMessageDialog: false,
      showEditCustomerDialog: false,
      showAddPaymentDialog: false,
      showUpdateCustomerInternetPlanDialog: false,
      showAddInstallationFeeDialog: false,
      showAddDebtDialog: false,
      showSendSmsDialog: false,
      showConfirmDeleteDialog: false,
      welcomeMessageDialogTitle: 'Send Welcome Message',
      welcomeMessageDialogMessage: 'Are you sure you want to send the Send portal credentials message?',
      welcomeMessageDialogAgreeText: 'Send',
      internetDisconnectDialogTitle: '',
      internetDisconnectDialogMessage: '',
      internetDisconnectDialogAgreeText: '',
      userId: this.$route.params.id,
      details: { customer: { name: '', discount: {}, customer_plan: { maintain_billing_date: 0 } }, has_internet_access: true },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Customers',
          disabled: false,
          to: { name: 'customers' },
        },
        {
          text: this.$route.params.id,
          disabled: true,
          to: {
            name: 'customers',
          },
        },
      ],
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiWifi,
        mdiWifiOff,
        mdiMenuDown,
        mdiInformationOutline,
      },
      tab: 'details',
      isSettingsLoaded: false,
      isSavingSettings: false,
      settings: {},
      errors: {},
    }
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
    smsUrl() {
      return `/sms?userId=${this.userId}`
    },
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    async getDetails() {
      this.isLoading = true
      try {
        const { data } = await axios.get(`/users/${this.userId}`)
        this.details = data
        this.breadcrumbs.pop()
        this.breadcrumbs.push({
          text: data.name,
          disabled: true,
          to: { name: 'customers' },
        })
        this.fetchSettings()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async toggleInternetStatus(NewInternetAccessStatus) {
      if (NewInternetAccessStatus) {
        this.internetDisconnectDialogOnAgree()
      } else {
        this.internetDisconnectDialogTitle = 'Disconnect Internet'
        this.internetDisconnectDialogMessage = `Are you sure you want to disconnect ${this.details.name}'s internet connection?`
        this.internetDisconnectDialogAgreeText = 'Disconnect'
        this.showInternetDisconnectDialog = true
      }
    },
    internetDisconnectDialogOnAgree() {
      const customerId = this.details.customer.id
      this.isTogglingInternetStatus = true

      axios.post(`toggle-internet-access/${customerId}`).then(response => {
        if (response.data.has_internet_access) {
          this.$toast.success('Internet connected successfully')
        } else {
          this.$toast.success('Internet disconnected successfully')
        }
      }).catch(error => {
        this.details.customer.has_internet_access = !this.details.customer.has_internet_access
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.isTogglingInternetStatus = false
        this.showInternetDisconnectDialog = false
      })
    },
    toggleCustomSettingsStatus() {
      const customerId = this.details.customer.id
      this.isTogglingUsesCustomSettingsStatus = true

      axios.post(`toggle-uses-custom-settings/${customerId}`).then(response => {
        this.settings = response.data.settings
      }).catch(error => {
        this.details.customer.uses_custom_settings = !this.details.customer.uses_custom_settings
        this.$toast.error(error.response.data.message)
      }).finally(() => {
        this.isTogglingUsesCustomSettingsStatus = false
      })
    },
    confirmAndSendWelcomeMessage() {
      this.showWelcomeMessageDialog = false
      this.sendWelcomeMessage()
    },
    sendWelcomeMessage() {
      this.isSendingWelcomeMessage = true
      const customerId = this.details.customer.id
      axios.post(`send-welcome-message/${customerId}`)
        .then(() => {
          this.$toast.success('Welcome message sent successfully')
          this.details.customer.welcome_notification_sent = true
        })
        .catch(error => {
          console.error(error)
          this.$toast.error('Error sending welcome message', { type: 'error' })
        })
        .finally(() => {
          this.isSendingWelcomeMessage = false
        })
    },
    deleteUser() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`customers/${this.details.customer.id}`)
        .then(() => {
          this.$toast.success('Customer deleted successfully')
          this.$router.push({ name: 'customers' })
        }).catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onExemptedFromDisconnection({ data }) {
      this.details.customer.has_disconnection_exemption = true
      this.details.customer.disconnection_exemption = data
      if (!this.details.customer.has_internet_access) {
        setTimeout(() => {
          this.$toast.info(`Switching on internet access for ${this.details.name}`)
          this.details.customer.has_internet_access = true
          this.toggleInternetStatus(true)
        }, 1000)
      }
    },
    onPlanChangeCanceled(amountToTopUp) {
      console.log('🚀 ~ file: CustomerDetails.vue:332 ~ onPlanChangeCanceled ~ amountToTopUp:', amountToTopUp)
      const amountToTopUpFloat = parseFloat(amountToTopUp)
      const customerDebtFloat = parseFloat(this.details.customer.debt)

      // Check if both conversions were successful
      if (Number.isNaN(amountToTopUpFloat) || Number.isNaN(customerDebtFloat)) {
        console.error('Invalid values:', { amountToTopUp, customerDebt: this.details.customer.debt })

        return
      }

      // Perform the operation
      this.details.customer.has_pending_plan_change = false
      this.details.customer.debt = (customerDebtFloat - amountToTopUpFloat).toString()
    },
    onPaymentAdded() {
      setTimeout(() => {
        this.getDetails()
      }, 1800)
    },
    fetchSettings() {
      const customerId = this.details.customer.id
      axios.get(`customer-settings/${customerId}`)
        .then(response => {
          this.settings = response.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            console.log(error)
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.isSettingsLoaded = true
        })
    },
  },
}
</script>

<style scoped>
.spacer-v {
  height: 35px;
}
.theme--light.v-tabs-items {
    background-color: transparent;
}

.theme--dark.v-tabs-items  {
    background-color: transparent;
}
</style>
