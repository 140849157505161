<template>
  <v-card :min-height="210">
    <v-card-title class="mt-8 mb-0 pb-1">
      Installation Fee Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            v-if="!customer && !isLoading"
            cols="12"
          >
            <h3 class="font-weight-light">
              Customer not found
            </h3>
          </v-col>
          <v-col
            v-for="detail in details"
            :key="detail.label"
            cols="12"
            md="3"
          >
            <details-text-shimmer v-show="isLoading" />
            <div
              v-if="customer"
              v-show="!isLoading"
            >
              <h4 class="font-weight-light">
                {{ detail.label }}
              </h4>
              <h4 class="font-weight-medium">
                <span v-if="isLoading">
                  <details-text-shimmer />
                </span>
                <span v-else>
                  <span v-if="detail.label === 'Status'">{{ getStatus(detail.value) }}</span>
                  <span v-else>Ksh {{ detail.value | formatCurrency }}</span>
                </span>
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    customer: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    details() {
      // Extracting the installation fees details from the customer object
      const installationFee = this.customer ? this.customer.installation_fee : 'N/A'
      const remainingFee = this.customer ? this.customer.remaining_installation_fee : 'N/A'
      const paidAmount = installationFee !== 'N/A' && remainingFee !== 'N/A'
        ? installationFee - remainingFee
        : 'N/A'

      return [
        { label: 'Installation Fee', value: installationFee },
        { label: 'Amount Paid', value: paidAmount },
        { label: 'Balance', value: remainingFee },
        { label: 'Status', value: remainingFee },
      ]
    },
  },
  methods: {
    // Determines the status based on remaining fee
    getStatus(remainingFee) {
      if (remainingFee === 0) {
        return 'Paid'
      } if (remainingFee < this.customer.installation_fee) {
        return 'Partially Paid'
      }

      return 'Not Paid'
    },
  },
}
</script>
